<style type="text/css" lang="scss">
body {
  height: 297mm;
  width: 210mm;
  margin-left: auto;
  margin-right: auto;
  background: url("~@/assets/receipt/bg.jpg") no-repeat;
  background-position: center top 50mm
}

h2 {
  color: #6ecf97;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 19.5pt;
}

p {
  color: #010101;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
  margin: 0pt;
}

.a,
a {
  color: #010101;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
  font-size: 11pt;
}

h1 {
  color: #6ecf97;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 25pt;
}

h3 {
  color: #6ecf97;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 14.5pt;
}

.s1 {
  color: #6ecf97;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 11pt;
  padding-top: 8pt;
}

.s2 {
  color: #6ecf97;
  font-family: "Times New Roman", serif;
  font-style: normal;
  font-weight: bold;
  text-decoration: none;
  font-size: 12pt;
}

table,
tbody {
  vertical-align: top;
  overflow: visible;
}
</style>

<template>
<div v-if="orderInfo != null" >
    <div style="float:left;margin:auto">
        <h2 style="padding-top: 0pt;padding-left: 0pt;text-indent: 0pt;text-align: left;">SwingBy</h2>
        <p style="padding-top: 0pt;text-indent: 0pt;text-align: left;">15 Allstate Pkwy, Unit 622</p>
        <p style="padding-top: 0pt;text-indent: 0pt;text-align: left;">Markham, ON L3R 5B4</p>
        <p style="padding-top: 0pt;text-indent: 0pt;text-align: left;">(905)-415-3055</p>
        <p style="padding-top: 0pt;text-indent: 0pt;text-align: left;"><a href="http://www.oceanorth.com/ " target="_blank ">www.oceanorth.com</a></p>
        <h3 style="padding-left: 4pt;text-indent: 0pt;text-align: left;background-color: #DAF3E5 ; line-height:150%;width:200pt;margin-bottom:0pt">Bill to </h3>
        <p style="text-indent: 0pt;text-align: left;line-height: 150%">Contact Name: {{orderInfo.deliveryInfo.contactName}}</p>
        <p style="text-indent: 0pt;text-align: left;line-height:150% ">Phone: {{orderInfo.deliveryInfo.contactPhoneNumber}}</p>
        <p style="text-indent: 0pt;text-align: left;line-height:150% ">{{deliveryAddress[0]}}</p>
        <p style="text-indent: 0pt;text-align: left;line-height:150% ">{{deliveryAddress[1]}}</p>
        <p style="margin-bottom:20pt"></p>
    </div>
    <div style="float:right;right:0pt;top:0pt;margin:auto">
        <h1 style="padding-top: 6pt;text-indent: 0pt;text-align: right; ">Receipt</h1>
        <p style="text-indent: 0pt;text-align: right; ">
            <div style="float:right;text-align:left;padding-left:10pt;background-color: #DAF3E5;width: 100pt;line-height: 190%;">
                <p>{{orderInfo.orderNumber}}</p>
            </div>
            <div style="float:right;text-align:right; padding-right:10pt;line-height: 190%;">
                <p>Receipt#</p>
            </div>
        </p>
        <p style="text-indent: 0pt;text-align: right;">
            <div style="float:right;text-align:left;padding-left:10pt;width: 100pt;line-height: 190%;">
                <p>{{months[date.getMonth()] + " " + date.getDate()+", " + date.getFullYear()}}</p>
            </div>
            <div style="float:right;text-align:right; padding-right:10pt;line-height: 190%;">
                <p>Date</p>
            </div>
        </p>
        <p style="text-indent: 0pt;text-align: right; ">
            <div style="float:right;text-align:left;padding-left:10pt;background-color: #DAF3E5;width: 100pt;line-height: 190%;">
                <p>{{orderInfo.consumer.nickname}}</p>
            </div>
            <div style="float:right;text-align:right; padding-right:10pt;line-height: 190%;">
                <p>UserID</p>
            </div>
        </p>
    </div>
    <div style="position:relative;">
        <p style="text-indent: 0pt;text-align: left; "></p>
        <table style="border-collapse:collapse;margin-left:6pt">
            <tr style="height:31pt;">
                <td style="width:78pt " bgcolor="#DAF3E5 ">
                    <p class="s1 " style="padding-left: 19pt;text-indent: 0pt;text-align: left; ">Order#</p>
                </td>
                <td style="width:233pt " bgcolor="#DAF3E5 ">
                    <p class="s1 " style="padding-left: 85pt;padding-right: 85pt;text-indent: 0pt;text-align: center; ">Description</p>
                </td>
                <td style="width:79pt " bgcolor="#DAF3E5 ">
                    <p class="s1 " style="padding-right: 28pt;text-indent: 0pt;text-align: center; ">Qty</p>
                </td>
                <td style="width:79pt " bgcolor="#DAF3E5 ">
                    <p class="s1 " style="padding-left: 22pt;text-indent: 0pt;text-align: left; ">Taxed</p>
                </td>
                <td style="width:78pt " bgcolor="#DAF3E5 ">
                    <p class="s1 " style="padding-left: 17pt;text-indent: 0pt;text-align: left; ">Amount</p>
                </td>
            </tr>
              <tr v-for="(item,index) in orderItems" v-bind:key="index" style="height:31pt;">
                <td style="width:78pt ">
                    <p style="padding-left: 19pt;text-indent: 0pt;text-align: left; ">{{item.orderNumber}}</p>
                </td>
                <td style="width:233pt ">
                    <p style="text-indent: 0pt;text-align: center; ">{{item.productName}}</p>
                </td>
                <td style="width:79pt ">
                    <p style="padding-right: 28pt;text-indent: 0pt;text-align: center; ">{{item.quantity}}</p>
                </td>
                <td style="width:79pt ">
                    <p style="padding-left: 22pt;text-indent: 0pt;text-align: left; ">{{item.taxRate}}</p>
                </td>
                <td style="width:78pt ">
                    <p style="padding-left: 17pt;text-indent: 0pt;text-align: left; ">{{item.totalPrice.display}}</p>
                </td>
            </tr>
        </table>
        <p style="text-indent: 0pt;text-align: left; "><br/></p>
        <p style="text-indent: 0pt;text-align: left; "><br/></p>
    </div>
    <div style="float:right">
        <p style="text-indent: 0pt;text-align: right; ">
            <div style="float:right;text-align:right;padding-left:10pt;padding-right:10pt;width: 100pt;line-height: 190%;">
                <p>{{ "$"+fees.subtotal.toFixed(2)}}</p>
            </div>
            <div style="float:right;text-align:right; line-height: 190%;">
                <p>Subtotal</p>
            </div>
        </p>
        <p style="text-indent: 0pt;text-align: right; ">
            <div style="float:right;text-align:right;padding-left:10pt;padding-right:10pt;width: 100pt;line-height: 190%;">
                <p>{{ "$"+fees.tax.toFixed(2) }}</p>
            </div>
            <div style="float:right;text-align:right;line-height: 190%;">
                <p>GST/HST</p>
            </div>
        </p>
        <p style="text-indent: 0pt;text-align: right; ">
            <div style="float:right;text-align:right;padding-left:10pt;padding-right:10pt;width: 100pt;line-height: 190%;">
                <p>{{ "$"+fees.delivery.toFixed(2) }}</p>
            </div>
            <div style="float:right;text-align:right;line-height: 190%;">
                <p>Delivery Fee</p>
            </div>
        </p>
        <p style="text-indent: 0pt;text-align: right; ">
            <div style="float:right;text-align:right;padding-left:10pt;padding-right:10pt;width: 100pt;line-height: 190%;">
                <p>{{ "$"+fees.service.toFixed(2) }}</p>
            </div>
            <div style="float:right;text-align:right;line-height: 190%;">
                <p>Service Fee</p>
            </div>
        </p>
        <div v-if="fees.discount != 0.0">
          <p style="text-indent: 0pt;text-align: right; "  >
              <div style="float:right;text-align:right;padding-left:10pt;padding-right:10pt;width: 100pt;line-height: 190%;">
                  <p>{{ "-$"+fees.discount.toFixed(2) }}</p>
              </div>
              <div style="float:right;text-align:right;line-height: 190%;">
                  <p>Discount</p>
              </div>
          </p>
        </div>
        <p style="text-indent: 0pt;text-align: right;">
            <img width=" 220 " src="@/assets/receipt/1.png" style="text-align: right " />
        </p>
        <p style="text-indent: 0pt;text-align: right; margin-top:-100pt; ">
            <div style="float:right;text-align:right;padding-left:10pt;padding-right:10pt;width: 100pt;line-height: 190%; ">
                <p>{{ "$"+fees.total.toFixed(2) }}</p>
            </div>
            <div style="float:right;text-align:right;line-height: 190%; ">
                <p>Total</p>
            </div>
        </p>
    </div>
    <div :style="`float:left; background: url(${img2}); width: 315px; height: 169px`">
        <h3 style="margin-top:6pt;padding-left: 2pt;text-indent: 0pt;text-align: left;float:left;">Other comments</h3>
    </div>
</div>
</template>
<script>
import { getMultipleOrderDetails } from '@/api/order';

export default {
  name: 'receipt',
  data() {
    return {
      img2: require('@/assets/receipt/2.png'),
      bg: require('@/assets/receipt/bg.jpg'),
      orderInfo: null,
      orderItems: [],
      deliveryAddress: [],
      months: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'June',
        'July',
        'Aug',
        'Sept',
        'Oct',
        'Nov',
        'Dec',
      ],
      fees: {
        subtotal: 0.0,
        tax: 0.0,
        delivery: 0.0,
        service: 0.0,
        discount: 0.0,
        total: 0.0,
      },
      date: new Date(),
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      let ids = this.$route.query.ids;
      if (typeof ids == 'string') {
        ids = [ids];
      }
      getMultipleOrderDetails({ ids }).then((response) => {
        this.orderInfo = response.data[0].order;
        for (let i = 0; i < response.data.length; i += 1) {
          const fee = response.data[i].order.fees;
          this.fees.subtotal += fee.subTotal.amount;
          this.fees.tax += fee.taxFee.amount;
          this.fees.delivery += fee.deliveryFee.amount;
          this.fees.service += fee.serviceFee.amount;
          this.fees.total += fee.total.amount;
          this.fees.discount += fee.discount.amount;
        }
        const deliveryAddressParts = this.orderInfo.deliveryInfo.addressDisplay.split(',');
        this.deliveryAddress.push(
          `${deliveryAddressParts[0]}, ${deliveryAddressParts[1]}`,
        );
        this.deliveryAddress.push(deliveryAddressParts[2]);
        for (const orderDetail of response.data) {
          const orderItems = orderDetail.order.lineItems;
          for (const orderItem of orderItems) {
            orderItem.orderNumber = orderDetail.order.orderNumber;
            this.orderItems.push(orderItem);
          }
        }
      });
    },
  },
};

</script>
